export default {
  footerV2: {
    container: {
      paddingX: [4, '', '', 10],
      paddingY: 8,
      backgroundColor: 'white',
      flexDirection: ['column', 'column', 'row', 'row'],
      justifyContent: ['center', '', '', 'flex-end'],
      alignItems: 'center',
      borderTop: '2px solid',
      '.contactDetails-container': {
        justifyContent: 'center',
        textAlign: 'center'
      },
      '.multiButtonContainer a': {
        margin: '0.5rem 0.25rem',
        border: '1px solid black',
        ':hover': {
          color: 'white'
        }
      },
      '.address': {
        textAlign: ['', '', '', 'right']
      },
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)'
      },
      '.socialIconsContainer svg': {
        width: '25px',
        height: '25px',
        marginRight: '0.5rem',
        path: {
          fill: 'black'
        }
      }
    },
    logo: {
      maxHeight: '150px',
      margin: ['0 auto 1rem']
    },
    logoColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: '1'
    },
    column: {
      flexDirection: 'column',
      padding: 2,
      width: ['100%', '100%', '60%'],
      alignItems: ['center', 'center', 'center', 'flex-end'],

      div: {
        justifyContent: ['', '', '', 'flex-end'],
        margin: '0.5rem',
        alignItems: ['', '', '', 'flex-end']
      }
    },

    popUpContentBoxesContainer: {
      margin: '0.5rem',
      '& > button': {
        padding: '1',
        fontSize: '0.8rem'
      }
    }
  }
}
