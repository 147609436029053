export default {
  menuV1: {
    container: {
      padding: ['3rem 0.5rem', '3rem 1rem'],
      justifyContent: 'center',
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    backToMenuBtn: {
      backgroundColor: 'primary',
      border: 'none',
      margin: '0.5rem',
      cursor: 'pointer',
      justifyContent: 'center',
      paddingBottom: 'calc(0.5rem - 1px)',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: 'calc(0.5rem - 1px)',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    },

    imageFill: {
      paddingBottom: '60%'
    },

    // Tabs

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px'
      }
    },

    // ================================
    // =========  ALL IN  =============
    // ================================

    allInContainer: {},
    menuContainer: { paddingBottom: '2rem' },

    // ================================
    // =========  CELLS  ==============
    // ================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
      margin: '2rem 0rem'
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))'],
      backgroundColor: 'primary',
      color: 'light',
      margin: ['1rem 0', '0.5rem']
    },

    menuCell: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      position: 'relative'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
      filter: 'brightness(0.75)'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellName: {
      fontSize: ['1.5rem', '', '', '2rem'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },

    // ================================
    // ========  Menu Sections ========
    // ================================

    menuSectionTitle: {
      fontSize: ['2.5rem', '', '3.5rem'],
      textAlign: 'center',
      margin: '2rem',
      fontWeight: 'bold'
    },
    menuSectionDescription: {
      textAlign: 'center',
      fontSize: 4,
      marginBottom: '1.25rem'
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0
    },

    // ================================
    // ========  Menu Items ===========
    // ================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem', '', '0 1rem'],
      marginBottom: '1rem',
      margin: ['', '0.5rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))'],
      position: 'relative'
    },
    menuItemContainerImgActive: {
      variant: 'menuV1.menuItemContainer'
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch'
    },
    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px']
    },
    secondMenuItemImage: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px'],
      marginRight: '0.5rem'
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      padding: '0 0.75rem 0 0',
      cursor: 'pointer'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },
    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      padding: '0rem'
    },
    menuItemName: {
      fontSize: ['1rem', '1.1rem', '', '1.5rem'],
      fontWeight: 'bolder',
      margin: '0px',
      paddingRight: '8px',
      fontFamily: 'Rae Dunn',
      fontWeight: '300',
      letterSpacing: '1px'
    },
    menuItemDescription: {
      fontSize: '0.9rem',
      whiteSpace: 'break-spaces',
      width: '90%'
    },

    // ================================
    // ======  Menu Items Price =======
    // ================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right'
    },

    variantsContainer: {
      marginTop: '0.5rem'
    },

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px'
    },
    menuItemPriceVariants: {},
    variantContainerFloatTopRight: {
      position: 'absolute',
      right: ['0.5rem', '', '1rem'],
      top: '0rem'
    },

    // ================================
    // ======= Section Dropdown =======
    // ================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative'
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '0.75rem 1.75rem'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0 2.5%',
      display: 'flex',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center'
      }
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    }
  }
}
